@import '~react-vis/dist/styles/plot';
@import '~react-vis/dist/styles/legends';
@import '~react-vis/dist/styles/radial-chart';
@import '~react-vis/dist/styles/treemap';
@import '~react-vis/dist/styles/examples';

//recharts

path.recharts-sector {
	@include themify($themes) {
		stroke: themed('colorBackground');
	}
}

.recharts-legend-item-text {
	@include themify($themes) {
		color: themed('colorText');
	}
}

.recharts-text {
	fill: $color-additional;
	opacity: 0.6;
}

.recharts-cartesian-axis-line {
	opacity: 0;
}

.recharts-cartesian-grid-horizontal line,
.recharts-cartesian-grid-vertical line {
	fill: $color-additional;
	opacity: 0.2;
}

.recharts-brush-slide {
	fill: #dee3eb;
	fill-opacity: 1;
}

.recharts-brush-traveller {
	transform: translateX(-8px);

	rect {
		fill: #ffffff;
		width: 12px;
		stroke: #dee3eb;
	}

	line {
		stroke: #dee3eb;
		transform: translateX(4px);
	}

	&:first-of-type {
		transform: translateX(0);
	}
}

.recharts-tooltip-cursor {
	fill-opacity: 0.2;
}

//chartsjs

.card {
	.chartjs-size-monitor {
		& + .card__title:not(:first-child) {
			margin-top: 0;
		}
	}
}

// rc-notification
.rc-notification {
	position: fixed;
	z-index: 100;
}

.rc-notification-notice {
	display: block;
	width: auto;
	line-height: 1.5;
	position: relative;
}

.rc-notification-notice-close {
	position: absolute;
	cursor: pointer;
	outline: none;
	font-weight: 700;
	line-height: 1;
	opacity: 0.2;
	text-decoration: none;
}

.rc-notification-notice-close-x:after {
	content: '×';
}

.rc-notification-fade-enter {
	opacity: 0;
	animation-duration: 0.3s;
	animation-fill-mode: both;
	animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
	animation-play-state: paused;
}

.rc-notification-fade-leave {
	animation-duration: 0.3s;
	animation-fill-mode: both;
	animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
	animation-play-state: paused;
}

.rc-notification-fade-enter.rc-notification-fade-enter-active {
	animation-name: rcNotificationFadeIn;
	animation-play-state: running;

	&.right-up {
		animation-name: rcNotificationRightFadeIn;
	}

	&.left-up {
		animation-name: rcNotificationLeftFadeIn;
	}
}

.rc-notification-fade-leave.rc-notification-fade-leave-active {
	animation-name: rcDialogFadeOut;
	animation-play-state: running;
}

@keyframes rcNotificationFadeIn {
	0% {
		transform: translateY(-100%);
		opacity: 0;
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes rcNotificationLeftFadeIn {
	0% {
		transform: translateX(-100%);
		opacity: 0;
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes rcNotificationRightFadeIn {
	0% {
		transform: translateX(100%);
		opacity: 0;
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes rcDialogFadeOut {
	0% {
		transform: translateY(0);
		opacity: 1;
	}
	100% {
		transform: translateY(-100%);
		opacity: 0;
	}
}

.rc-notification {
	left: auto !important;
	top: 60px !important;
	height: 0;

	& > span {
		height: 0;
		display: block;
	}
}

.rc-notification-notice-close {
	right: 45px;
	top: 15px;
	font-size: 26px;
	line-height: 1;
	font-weight: 300;
	color: #646777;
	text-shadow: none;
	opacity: 0.2;

	&:hover {
		opacity: 0.5;
		color: #646777;
	}
}

.add-new-btn {
	// position: absolute !important;
	// right: 110px !important;
	// margin-top: 35px !important;
	// z-index: 1 !important;
	height: 30px;
	padding: 3px 20px !important;
	margin-bottom: 0px !important;
}

.margin-top-30 {
	margin-top: 30px;
}

.margin-top-60 {
	margin-top: 60px;
}

.system-btn {
	height: 30px;
	padding: 3px 20px !important;
	margin-bottom: 0px !important;
	margin-top: 0px !important;
}

.sign-in-card {
	width: 700px !important;
	border-radius: 5px;
	padding: 75px 60px !important;
}

.sign-in-logo {
	height: 42px !important;
	width: 115px !important;
	float: right !important;
}

.sign-in-backround {
	height: 100% !important;
	background: #4d86b7 !important;
}

.dropzone-img-width {
	width: 80px !important;
	padding: 8px !important;
}

.full-modal {
	width: 1500px !important;
}

.error-message {
	color: red;
}

.card-body-width {
	width: 800px;
}

.phone-input {
	margin: 25px 0px 0px 0px !important;
}

.phone-input-staff {
	margin: 20px 0px 0px 0px !important;
}

.img-error {
	width: 100% !important;
	text-align: center !important;
}

.clear-border {
	border: none;
	padding: 4px 0px !important;
	width: 25px;
}

.font-weight-500 {
	font-weight: 500 !important;
}

.switch-staff {
	margin: 0px !important;
	width: 95% !important;
}

.border-left-signup {
	border-left: 1px solid gainsboro;
}

.menu-icon-size {
	font-size: 18px !important;
}

.autocomplete-dropdown-container {
	// top:50px;
	width: 450px !important;
	position: absolute;
	z-index: 99;
	white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
	white-space: -pre-wrap; /* Opera 4-6 */
	white-space: -o-pre-wrap; /* Opera 7 */
	white-space: pre-wrap; /* css-3 */
	word-wrap: break-word; /* Internet Explorer 5.5+ */
	white-space: -webkit-pre-wrap; /* Newer versions of Chrome/Safari*/
	word-break: break-all;
	white-space: normal;
}

.margin-right-menu {
	margin-right: 8px;
}

.react-phone-number-input__icon-image {
	margin-top: -10px !important;
}

.forgot-password-link {
	display: flex;
	color: #6f6f6f;
	font-size: 13px;
	margin: 10px 9px;
	position: absolute;
	right: 12px;
}

.create-staff-popup {
	width: 70%;
	max-width: 70% !important;
}

.switch-staff-sub {
	margin-left: 25px !important;
	width: 80%;
	margin: -5px;
	float: left;
}

.access-staff-div {
	max-height: 270px;
	overflow: auto;
}

.select-menu-width {
	width: auto;
	min-width: 300px;
	max-height: 400px;
}

.licence-list {
	margin-left: 5px;
	padding: 15px;
}

.invite-btn {
	float: right;
	margin-top: 30px;
	margin-right: 20px !important;
}

.delete-invite-btn {
	margin-top: 20px !important;
}

.flex-display {
	display: flex;
}

.plan-back-btn {
	float: right;
	margin-bottom: 30px !important;
	margin-right: 30px !important;
}

.notification-size {
	max-height: 400px;
	overflow: auto;
}

.material-master-row {
	// border-left: "1px solid #c5c5c5" !important;
	border: 2px solid rgb(232, 232, 232) !important;
	margin-top: 25px;
}

.material-child-btn {
	margin: 15px 0px !important;
	padding: 5px 15px !important;
}

.material-child-btn-right {
	margin: 15px 0px !important;
}

.margin__left_10 {
	margin-left: 10px !important;
}

.min__width_fit {
	min-width: fit-content !important;
}

.new-user-modal-row {
	background: #fff !important;
	border-radius: 5px !important;
}

.max__width_90 {
	max-width: 90% !important;
}

.profile-display-name {
	text-transform: none !important;
}

.min__width_150 {
	min-width: 150px !important;
}

.full__width {
	width: 100% !important;
}

.width__70 {
	width: 70% !important;
	max-width: 70% !important;
	min-width: 70% !important;
}
.width__50 {
	width: 50% !important;
	max-width: 50% !important;
	min-width: 50% !important;
}

.width__80 {
	width: 80% !important;
	max-width: 80% !important;
	min-width: 80% !important;
}

.margin__top {
	margin-top: 1.5rem !important;
}

.margin__bottom {
	margin-bottom: 1.5rem !important;
}

.margin__top_15 {
	margin-top: 15px !important;
}

.border_box {
	border: 1px solid var(--clr-aexdo); //groove;
	padding: 0.5rem;
	margin-top: 0.5rem;
	h4 {
		color: var(--clr-aexdo);
	}
}

.position__absolute {
	position: absolute !important;
}

.register-row {
	background: #fff !important;
	border-radius: 5px !important;
}

.pull__right {
	float: right;
}
.width__150 {
	width: 150px;
}

.margin__bottom_10 {
	margin-bottom: 10px !important;
}
.remove-btn {
	position: relative;
	top: 16px;
	left: 0px;
}

.remove-btn span {
	font-size: 24px;
}

.dropzone-pr .dropzone__drop-here {
	margin: 60px 0;
	text-align: center;
}

.material-box {
	margin: 10px 0px 0px 0px;
}

.material-box label {
	margin-bottom: 0.2px;
}

.float__right {
	float: right !important;
}

.margin__auto {
	margin: auto !important;
}

.margin__auto_20 {
	margin: 20px 20px 20px 20px !important;
}

.visible-icon {
	cursor: pointer;
}

.maxWidth__250 {
	max-width: 250px !important;
}
.btn-spinner {
	color: #fff;
}
.spinner_color {
	color: #e64823;
}
.spinner_size {
	height: 25px;
	width: 25px;
}
.div-spinner {
	width: 100%;
	text-align: center;
}

.div-spinner .spinner-border {
	color: #e64823;
}

.disable__btn:disabled {
	background-color: rgba(255, 72, 97, 0.5) !important;
}

.self_message_time {
	left: 0;
}

.othercompany__superadmin {
	background-color: #5de1b6;
	padding: 2px 5px;
	border-radius: 5px;
}

.doc__icon {
	border: 1px solid #bdbcbc;
	border-radius: 3px;
	display: inline-flex;
	text-align: center;
	vertical-align: middle;
	padding: 3px 4px;
	margin: 5px;
	Icon {
		font-size: 25px;
		color: #656565;
	}
}
.btn-file {
	position: relative;
	overflow: hidden;
}

.btn-file input[type='file'] {
	position: absolute;
	top: 0;
	right: 0;
	min-width: 100%;
	min-height: 100%;
	font-size: 100px;
	text-align: right;
	filter: alpha(opacity=0);
	opacity: 0;
	outline: none;
	background: white;
	cursor: text;
	display: block;
}

.messagesWrapper {
	display: flex;
	flex-direction: column;
	height: 100vh;
	//overflow-y: scroll;
}

.active_project {
	display: inline-block;
	margin-right: 20px;
	font-size: 14px;
	min-width: 150px;
}

.active_project_btn {
	margin-top: 8px;
}
.orange_color {
	color: #e64823 !important;
}
.border-class {
	border: 1px solid #c6c6c6;
	border-radius: 2px;
	background-color: #ffffff;
	padding-top: 5px;
	padding-bottom: 5px;
}
.bgHover:hover {
	background-color: #e64823;
	color: white;
	.colorRed {
		color: white !important;
	}
	.colorRedBorder {
		border: 1px solid white !important;
	}
}
.bgHover1:hover {
	background-color: #dfdfdf;
}
.selectedColorCampaign {
	background-color: #e64823;
	color: white;
}
.border-class-detail {
	border: 1px solid #c6c6c6;
	padding: 15px;
	// margin-left: 20px;
	cursor: pointer;
}
.margin-5px {
	margin-top: 8px !important;
}

.document-class {
	width: 150px;
}
.document-class span {
	margin-left: 10px;
}
.popover {
	z-index: 1050 !important;
	border: 1px solid #c6c6c6 !important;
}
.email-icon {
	margin-left: -11px;
	cursor: pointer;
	color: #e64823;
}

#tableCustom td,
#tableCustom th {
	border: 1px solid #fff;
	padding: 8px;
}
#tableCustom td {
	background: #ddd;
	text-align: center;
}
#tableCustom {
	margin-left: 16%;
	margin-top: 1%;
	margin-bottom: 1%;
}

#tableCustom th {
	text-align: left;
	background-color: #ec6747;
	color: #ffffff;
	font-weight: 800;
	font-size: 13px;
}

.ck.ck-editor__main > .ck-editor__editable:focus {
	border-color: #e64823 !important;
}

.btnRemoveContent:before {
	content: none !important;
}

.MuiTableCell-root {
	padding: 8px 0px 8px 15px !important;
	line-height: 2.3 !important;
}

.MuiTableCell-head {
	padding: 15px 0px 15px 15px !important;
}

.TodoHistory {
	.Mui-disabled {
		color: #000;
	}
	.MuiFormLabel-root.Mui-disabled {
		color: #000;
	}
	.MuiFormLabel-root {
		color: #000;
	}
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
	background-color: #e64823 !important;
	border-color: #e64823 !important;
}

.campainTitle {
	float: left;
}
//@extend{
//border: 1px solid #bdbdbd;
//margin-left: 64%;
//font-size: 14px;
//}

.colorYello {
	color: #c6ca1f !important;
	border: 1px solid #c6ca1f !important;
}
.colorWhite {
	color: #fff !important;
	border: 1px solid #fff !important;
}
.colorYelloBorder {
	color: #c6ca1f !important;
}

.colorRed {
	color: red !important;
}

.colorRedBorder {
	border: 1px solid red !important;
}

.colorGreen {
	color: green !important;
}
.colorGreenBorder {
	border: 1px solid green !important;
}
.MuiSnackbarContent-message {
	max-width: 500px !important;
}
.highcharts-credits {
	display: none;
}

#scrollbarWrapper1,
#scrollbarWrapper2 {
	width: 100%;
	border: none 0px RED;
	overflow-x: scroll;
	overflow-y: hidden;
}
#scrollbarWrapper1 {
	height: 20px;
}
#scrollbarWrapper2 {
	height: 590px;
	overflow-y: auto;
}
#scrollbarDiv1 {
	height: 20px;
}
#scrollbarDiv2 {
	height: 590px;
	background-color: #ffffff;
	//overflow: auto;
}

.theme-light .topbar__link-icon {
	color: #000000 !important;
}

.markasread:hover {
	background-color: #e64823;
	color: white !important;
	font-weight: 900;
}

.todo_history_border_box {
	border: 2px solid #80808098;
	margin-left: -5px;
}
#printedArea {
	height: 500px !important;
}
#editexcellink:hover {
	color: #fff !important;
}

/* Hide the Mapbox logo start */

.mapboxgl-ctrl-logo {
	display: none !important;
}

.mapboxgl-ctrl-attrib-inner {
	display: none;
}

.mapboxgl-ctrl-attrib.mapboxgl-compact {
	display: none;
}

/* Hide the Mapbox logo end */

.theme-light #panel__refresh_dashboard:before {
	background-color: transparent !important;
}
.AccordionCustom .MuiAccordionSummaryCls {
	margin-bottom: -25px !important;
}
.AccordionCustom .AccordionDetailsCls {
	padding: 8px 16px 3px !important;
	background: #f0f2f578;
	border-top: 1px solid #ebedf0;
}

.list1 {
	padding: 5px;
	width: 190px;
	background-color: lightgrey;
}

.list1.draggingOver {
	background-color: lightblue;
}

.item1 {
	user-select: none;
	padding: 10px;
	margin: 0;
	margin-bottom: 8px;
	background-color: #fff;
}

.item1.dragging {
	background-color: pink;
}

.select__option--is-selected {
	background-color: #e64823;
	color: white;
}

.wrap_icons_task_sidebar {
	box-shadow: none !important;

	.activity_icon {
		display: inherit;
		font-size: 20px;
	}

	.hover_icon {
		display: none;
		color: #e64823;
	}

	&:hover {
		.hover_icon {
			display: block;
		}

		.activity_icon {
			display: none;
		}
	}
}

.css-1szy77t-control {
	border-color: #e64823 !important;
	box-shadow: 0 0 0 1px #e64823 !important;
}

.css-1szy77t-control:hover {
	border-color: #e64823 !important;
	box-shadow: 0 0 0 1px #e64823 !important;
}

/*  Analysis Table custom table css start  */

.reportFirstrow {
	border-top: 2px solid #000;
}

.reportanalysisResult table,
.reportanalysisResult th,
.reportanalysisResult td {
	border: 1px solid #000000;
}

.reportHeight {
	height: 600px;
	overflow: auto;
}

.reportHeightProDtlSelInfo {
	height: 40px;
}

// .reportPagebreak{
//   page-break-after: always;
// }

/*  Analysis Table custom table css end  */
.reportCard {
	padding-bottom: 20px !important;
	.card-body {
		height: 295px;
		overflow: hidden auto;
		padding: 5px 20px 20px 20px;
	}
}

.rotateBtn {
	background-color: #fff !important;
}

.rotateBtn:before {
	background-color: lightgrey !important;
}

.css-dpec0i-option {
	background-color: #e64823 !important;
	color: #fff !important;
}

.reactglobaltooltip {
	padding: 4px !important;
}

.css-5ih5ya-group {
	color: #000 !important;
	font-size: 100% !important;
}

.css-b9fwbw-option {
	color: #fff !important;
}

.pollutantslist .btn.btn-danger,
.btn.btn-danger:before,
.btn.btn-danger:hover,
.btn.btn-danger:focus,
.btn.btn-danger:active,
.btn.btn-danger:active:focus {
	background-color: #e64823 !important;
}

.disposallist .btn.btn-danger,
.btn.btn-danger:before,
.btn.btn-danger:hover,
.btn.btn-danger:focus,
.btn.btn-danger:active,
.btn.btn-danger:active:focus {
	background-color: #e64823 !important;
}

.statusOpen,
.statusClose,
.statusDeleted,
.statusPending {
	font: caption;
	font-variant-caps: all-small-caps;
	font-weight: 600;
}

.statusOpen {
	color: green;
}

.statusClose {
	color: #dd3333;
}

.statusDeleted {
	color: red;
}

.statusPending {
	color: #ffc107;
}

.probingSize {
	font-size: 19px !important;
}

/* For filled triangle symbols */
@font-face {
	font-family: 'Material Symbols Outlined';
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/sandbox/materialsymbolsoutlined/v7/kJF1BvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oDMzByHX9rA6RzazHD_dY43zj-jCxv3fzvRNU22ZXGJpEpjC_1n-q_4MrImHCIJIZrDCvHOej.woff2)
		format('woff2');
}
.material-symbols-outlined {
	font-family: 'Material Symbols Outlined';
	font-weight: normal;
	font-style: normal;
	font-size: 24px;
	line-height: 1;
	letter-spacing: normal;
	text-transform: none;
	display: inline-block;
	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
	font-feature-settings: 'liga';
	-webkit-font-feature-settings: 'liga';
	-webkit-font-smoothing: antialiased;
}
